'use client'

import { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Carousel } from 'maker-ui/carousel'
import { cn } from 'maker-ui/utils'

import { HomePageData } from '@/cms'
import styles from './HomeCarousel.module.scss'

interface HomeCarouselProps {
  data: HomePageData['hero']
}

export const HomeCarousel = ({ data }: HomeCarouselProps) => {
  const [show, set] = useState(true)
  return (
    <Carousel
      classNames={{ root: styles.carousel, arrow: styles.arrow }}
      overlay={
        <div
          className={cn(
            styles.overlay,
            'absolute flex align-center justify-center'
          )}>
          {show ? (
            <div className={styles.text}>
              <h1>{data.title}</h1>
              <p>{data.text}</p>
              <Link
                href={data.button.path}
                className={cn('btn-primary', styles.btn)}>
                {data.button.label}
              </Link>
              <button className={styles.btn_hide} onClick={() => set(false)}>
                Hide
              </button>
            </div>
          ) : null}
        </div>
      }>
      {data.images.map((image, i) => (
        <div className={styles.image} key={i}>
          <Image
            fill
            src={image}
            alt={`slide ${i} image`}
            style={{ objectFit: 'cover' }}
            priority={i < 2}
            sizes="100vw"
          />
        </div>
      ))}
    </Carousel>
  )
}
